/* Small phone */
@media only screen and (max-width: 600px) {
    body,html{
        font-size: 16px;
    }
    .container{
        padding: 0;
    }
    .menu_custon{
        padding-left: 1rem;
        display: none;
    }
    .v-application ul, .v-application ol{
        padding: 0;
    }
    .menu {
        flex-direction: column;
    }
    .submenu {
        width: 100% !important;
        position: static;
        display: none;
    }

    .submenu li {
        border-bottom: none;
        padding-left: 1rem;
    }

    .menu li {
        margin-bottom: 0 !important;
    }
    .v-dialog{
        width: 100% !important;
        margin: 0 !important;
    }
    .v-dialog > .v-card > .v-card__text{
        padding: 5px;
    }
    .column-reverse {
        flex-direction: column-reverse;
    }
}
