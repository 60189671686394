/* Phone */
@media only screen and (min-width: 600px) and (max-width: 960px) {
    body,html{
        font-size: 19px;
    }
    .container{
        padding: 0;
    }
    .v-dialog{
        width: 100% !important;
        margin: 0 !important;
    }
    .column-reverse {
        flex-direction: column-reverse;
    }
}
