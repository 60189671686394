@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

.footer_custom {
  justify-content: center;
}
.v-toolbar__content {
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}
.date_time {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.date_time_font {
  color: #000000;
  font-size: 1.2rem;
}
.table_custom_limit {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #8f8f8f;
  font-size: 1.2rem;
  margin-top: 1.5rem;
}
.table_custom_limit th {
  border: 1px solid #EBEDF3;
  font-weight: 600 !important;
  padding-block: 5px;
  background: #0686E8;
  color: #EBEDF3;
}
.table_custom_limit td {
  border: 1px solid #8f8f8f;
  font-weight: 500 !important;
  padding-left: 0.3rem;
}
.v-input--selection-controls__input {
  top: 1px;
  border: 1px solid #000000;
}
.v-text-field-limit {
  padding-top: 0 !important;
  margin-top: 0 !important;
  width: 100%;
  margin-left: 1rem;
  font-size: 1.2rem;
}
.row-form {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.col-custom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.btn_limit {
  width: 100%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #0686E8;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}
.btn_limit:disabled {
  opacity: 0.4;
}
.delete_btn {
  background: red;
}
.delete_btn_cut {
  background: none;
  color: red !important;
}
.close_btn {
  background: red;
  padding-block: 20px !important;
  padding-inline: 20px !important;
}
.close_lotto {
  background: #03c707;
  padding-block: 20px !important;
  padding-inline: 20px !important;
  color: white !important;
}
.close_lotto span {
  color: #EBEDF3 !important;
  font-weight: bold;
}
.open_lotto {
  background: #0306c7;
  padding-block: 20px !important;
  padding-inline: 20px !important;
  color: white !important;
}
.open_lotto span {
  color: #EBEDF3 !important;
  font-weight: bold;
}
.close_btn span {
  color: #EBEDF3 !important;
  font-weight: bold;
}
.loading_image_main {
  margin-block: -10rem;
}
.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}
.v-input__slot {
  display: flex !important;
  justify-content: center;
  position: relative !important;
  top: 7px;
}
.is_close_row {
  background: #ff2222;
}
.is_close_row td {
  color: #ffffff;
  border: solid 1px #ffffff;
}
.table_container {
  height: 40rem;
  overflow-y: scroll;
}
.list_label {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
}
.head_font {
  font-size: 1.1rem;
  margin-top: 0.5rem;
}
.button_list {
  display: flex;
  width: 100%;
  gap: 3px;
  margin-top: 1rem;
}
.btn_del_cut {
  width: 40%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #e80606;
  margin: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 5px;
}
.btn_del_cut_full {
  width: 100%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #e80606;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}
.btn_del_cut_all {
  width: 50%;
  padding: 10px;
  padding-block: 10px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #e80606;
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 5px;
}
.btn_send {
  width: 40%;
  padding: 10px;
  padding-block: 15px;
  border-radius: 10px;
  color: #EBEDF3;
  background: #0686E8;
  margin: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 5px;
}
.center_list {
  justify-content: center;
}
.input-custom {
  border: 1px solid #c7c7c7;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin: 0.2rem;
}
.form-custom {
  display: flex;
  justify-content: center
}
.container-button-list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.btn_submit {
  font-size: 1.5rem;
  color: #EBEDF3 !important;
  background: #0686E8;
  padding: 0.5rem;
  padding-inline: 3rem;
}
.error-text {
  color: #ff0000;
  font-size: 1.2rem;
}
.list_award {
  display: flex;
  font-size: 1.4rem;
  gap: 20px;
  font-weight: bold;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.list_award .label {
  font-weight: 400;
}
.list_award font:first-child {
  width: 10rem;
  text-align: left;
}
.list_award font:last-child {
  width: 20rem;
  text-align: left;
}
.container-list-award {
  width: 30rem !important;
}
.list_type_cut {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.cut_type_btn {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  margin-inline: 0.2rem;
  background: #0686E8;
  border-radius: 10px;
  color: #EBEDF3;
  background: #0686E8;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: bold;
}
.ip_cut {
  width: 5rem;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.74);
  font-weight: normal;
}
.row_custom {
  margin-block: -2px !important;
}
.print-icon {
  color: #0686E8 !important;
  font-size: 3rem;
  padding: 1rem;
  padding-bottom: 0;
}
.table-custom-container {
  height: 30rem;
  width: 100%;
  overflow-y: scroll;
}
.time_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.time_ip {
  width: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.64);
  font-size: 1.2rem;
  text-align: center;
}
.time_ip:focus-visible {
  width: 50%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.64);
  border-top: none !important;
  font-size: 1.2rem;
  text-align: center;
}
.custom_switch {
  border: none !important;
}
.theme--light.v-label {
  color: black !important;
}
.custom_switch .v-input__control .v-input__slot .v-input--selection-controls__input {
  border: none !important;
}


body{
  background: #48090A !important;
}
.theme--light.v-application{
  background: #48090A !important;
}
.form_login{
  width: 50rem;
  height: auto;
  padding:3rem;
  background: #ffffff;
  border-radius: 0.5rem;
}
.login_container{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #0686E8;
}
.notfound{
  font-size: 1rem;
  color: red;
}
.v-application .success{
  background-color: #09910c !important;
  border-color: #09910c !important;
}


.card_container[data-v-4333fdd2] {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header[data-v-4333fdd2] {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
}
.card_content[data-v-4333fdd2] {
  padding: 1rem;
}
.img_thumb[data-v-4333fdd2] {
  width: 8rem;
  border-radius: 10px;
  margin: 0.2rem;
}
.form_action[data-v-4333fdd2] {
  padding-right: 5rem;
  padding-left: 5rem;
}
.image_preview_container[data-v-4333fdd2] {
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview[data-v-4333fdd2] {
  width: 20rem;
  text-align: center;
}
.btn_sale[data-v-4333fdd2] {
  background: #e7e7e7;
  padding: 0.5rem;
  padding-inline: 2rem;
  color: #0686E8;
  font-weight: bold;
  border-radius: 10px;
  padding-right: 3rem;
  padding-left: 3rem;
  min-width: 10rem;
  font-size: 1rem;
}
.btn_sale[data-v-4333fdd2]:active {
  background: #0686E8;
  color: #FFFFFF;
}
.btn_sale.active[data-v-4333fdd2] {
  background: #0686E8;
  color: #FFFFFF;
}
.button_list[data-v-4333fdd2] {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.sale_container[data-v-4333fdd2] {
  flex-direction: column;
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_cal[data-v-4333fdd2] {
  display: flex;
  gap: 1rem;
  font-size: 2rem;
  align-items: center;
  width: 100%;
  justify-content: center
}
.input-custom[data-v-4333fdd2] {
  border: 1px solid;
  max-width: 6rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  background: white;
}
.input-custom-pick[data-v-4333fdd2] {
  border: 1px solid;
  max-width: 4.8rem;
  font-size: 1.2rem;
  padding: .5rem;
  background: white;
}
.table_custom[data-v-4333fdd2] {
  margin-top: 5rem;
  border-collapse: collapse;
  width: 100%;
}
.head_title[data-v-4333fdd2] {
  font-size: 1.2rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}
.head_title_sub[data-v-4333fdd2] {
  font-size: 1.1rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}
.table_list[data-v-4333fdd2] {
  width: 100%;
  display: flex;
  justify-content: center;
}
.data_table[data-v-4333fdd2] {
  padding: 0.2rem;
  font-size: 1rem;
  text-align: center;
}
.table_custom_summary[data-v-4333fdd2] {
  margin-top: 5rem;
  border-collapse: collapse;
  width: 100%;
}
.head_title_summary[data-v-4333fdd2] {
  font-size: 1.2rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}
.head_title_sub_summary[data-v-4333fdd2] {
  font-size: 1.1rem;
  padding: 0.5rem;
  background: #0686E8;;
  color: #ffffff;
}
.table_list_summary[data-v-4333fdd2] {
  width: 80%;
  display: flex;
  justify-content: center;
}
.data_table_summary[data-v-4333fdd2] {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
}
.delete_btn[data-v-4333fdd2] {
  color: red !important;
}
.btn_sale[data-v-4333fdd2]:disabled {
  filter: saturate(0);
}
.input-custom[data-v-4333fdd2]:disabled {
  filter: saturate(0);
}
.loading_image[data-v-4333fdd2] {
  height: 8rem;
  margin-bottom: -5rem;
}
.select-custom[data-v-4333fdd2]{
  border: 1px solid #000000;
  padding: 0.3rem;
  -webkit-appearance: button;
  font-size: 1rem;
}
.filter-list[data-v-4333fdd2]{
  width: 100%;
}
.delete_btn[data-v-4333fdd2]{
  background: none !important;
}
.input-custom[data-v-4333fdd2]::-webkit-outer-spin-button,
.input-custom[data-v-4333fdd2]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-custom[type=number][data-v-4333fdd2] {
  -moz-appearance: textfield;
}
.is_limit[data-v-4333fdd2]{
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  color: red;
  cursor: pointer;
}
.list_input[data-v-4333fdd2]{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  gap: 10px;
}
.list_left[data-v-4333fdd2]{
 justify-content: flex-end;
}
.list_right[data-v-4333fdd2]{
  justify-content: flex-start;
}


@media print {
.menu_custom_print[data-v-90446186]{
    display: none;
}
.print-icon-contrainer[data-v-90446186]{
    display: none;
}
}
@media screen {
.card_container[data-v-90446186] {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
}
.card_header[data-v-90446186] {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}
.card_content[data-v-90446186] {
    padding: 1rem;
}
.img_thumb[data-v-90446186] {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
}
.form_action[data-v-90446186] {
    padding-right: 5rem;
    padding-left: 5rem;
}
.image_preview_container[data-v-90446186] {
    display: flex;
    justify-content: center;
    align-items: center;
}
#image_preview[data-v-90446186] {
    width: 20rem;
    text-align: center;
}
.btn_sale[data-v-90446186] {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
}
.btn_sale[data-v-90446186]:active {
    background: #0686E8;
    color: #FFFFFF;
}
.btn_sale.active[data-v-90446186] {
    background: #0686E8;
    color: #FFFFFF;
}
.button_list[data-v-90446186] {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.sale_container[data-v-90446186] {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_cal[data-v-90446186] {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
}
.input-custom[data-v-90446186] {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
}
.table_custom[data-v-90446186] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title[data-v-90446186] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub[data-v-90446186] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list[data-v-90446186] {
    width: 100%;
    display: flex;
    justify-content: center;
}
.data_table[data-v-90446186] {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
}
.table_custom_summary[data-v-90446186] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title_summary[data-v-90446186] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub_summary[data-v-90446186] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list_summary[data-v-90446186] {
    width: 80%;
    display: flex;
    justify-content: center;
}
.data_table_summary[data-v-90446186] {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
}
.delete_btn[data-v-90446186] {
    color: red !important;
}
.btn_sale[data-v-90446186]:disabled {
    filter: saturate(0);
}
.input-custom[data-v-90446186]:disabled {
    filter: saturate(0);
}
.loading_image[data-v-90446186] {
    height: 8rem;
    margin-bottom: -5rem;
}
.header_top_report[data-v-90446186]{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table-custom[data-v-90446186]{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
}
.table-custom tr th[data-v-90446186]{
    padding: 0.5rem;
    padding-inline: 2rem;
}
.table-custom tr td[data-v-90446186]{
    padding: 0.5rem;
    text-align: center;
}
.table-custom tr td.sum_text[data-v-90446186]{
    text-align: left;
}
.report_table[data-v-90446186]{
    padding: 0.5rem;
}
.loading_image_main[data-v-90446186]{
    margin-block: -5rem;
}
.print-icon-contrainer[data-v-90446186]{
    width: 100%;
    padding:1rem;
}
.print-icon[data-v-90446186]{
    color: #0686E8 !important;
    font-size: 3rem;
    padding: 1rem;
    padding-bottom: 0;
}
}



@media print {
.menu_custom_print[data-v-d20dfc1a]{
    display: none;
}
}
@media screen {
.card_container[data-v-d20dfc1a] {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
}
.card_header[data-v-d20dfc1a] {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}
.card_content[data-v-d20dfc1a] {
    padding: 1rem;
}
.img_thumb[data-v-d20dfc1a] {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
}
.form_action[data-v-d20dfc1a] {
    padding-right: 5rem;
    padding-left: 5rem;
}
.image_preview_container[data-v-d20dfc1a] {
    display: flex;
    justify-content: center;
    align-items: center;
}
#image_preview[data-v-d20dfc1a] {
    width: 20rem;
    text-align: center;
}
.btn_sale[data-v-d20dfc1a] {
    background: #F8CD6D;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #48090a;
    font-weight: bold;
}
.btn_sale[data-v-d20dfc1a]:active {
    background: #48090a;
    color: #F8CD6D;
}
.btn_sale.active[data-v-d20dfc1a] {
    background: #48090a;
    color: #F8CD6D;
}
.button_list[data-v-d20dfc1a] {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.sale_container[data-v-d20dfc1a] {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_cal[data-v-d20dfc1a] {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
}
.input-custom[data-v-d20dfc1a] {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
}
.table_custom[data-v-d20dfc1a] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title[data-v-d20dfc1a] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #48090a;;
    color: #f8cd6d;
}
.head_title_sub[data-v-d20dfc1a] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #48090a;;
    color: #f8cd6d;
}
.table_list[data-v-d20dfc1a] {
    width: 100%;
    display: flex;
    justify-content: center;
}
.data_table[data-v-d20dfc1a] {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
}
.table_custom_summary[data-v-d20dfc1a] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title_summary[data-v-d20dfc1a] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #48090a;;
    color: #f8cd6d;
}
.head_title_sub_summary[data-v-d20dfc1a] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #48090a;
    color: #f8cd6d;
}
.table_list_summary[data-v-d20dfc1a] {
    width: 80%;
    display: flex;
    justify-content: center;
}
.data_table_summary[data-v-d20dfc1a] {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
}
.delete_btn[data-v-d20dfc1a] {
    color: red !important;
}
.btn_sale[data-v-d20dfc1a]:disabled {
    filter: saturate(0);
}
.input-custom[data-v-d20dfc1a]:disabled {
    filter: saturate(0);
}
.loading_image[data-v-d20dfc1a] {
    height: 8rem;
    margin-bottom: -5rem;
}
.header_top_report[data-v-d20dfc1a]{
    padding: 1rem;
    background: #48090a;
    color: #f8cd6d;
}
.table-custom[data-v-d20dfc1a]{
    font-size: 1.2rem;
    border-collapse: collapse;
}
.table-custom tr th[data-v-d20dfc1a]{
    padding: 0.5rem;
    padding-inline: 2rem;
}
.report_table[data-v-d20dfc1a]{
    padding: 1rem;
}
}



@media print {
.menu_custom_print[data-v-3aaf2527]{
    display: none;
}
}
@media screen {
.card_container[data-v-3aaf2527] {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
}
.card_header[data-v-3aaf2527] {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}
.card_content[data-v-3aaf2527] {
    padding: 1rem;
}
.img_thumb[data-v-3aaf2527] {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
}
.form_action[data-v-3aaf2527] {
    padding-right: 5rem;
    padding-left: 5rem;
}
.image_preview_container[data-v-3aaf2527] {
    display: flex;
    justify-content: center;
    align-items: center;
}
#image_preview[data-v-3aaf2527] {
    width: 20rem;
    text-align: center;
}
.btn_sale[data-v-3aaf2527] {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
}
.btn_sale[data-v-3aaf2527]:active {
    background: #0686E8;
    color: #FFFFFF;
}
.btn_sale.active[data-v-3aaf2527] {
    background: #0686E8;
    color: #FFFFFF;
}
.button_list[data-v-3aaf2527] {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.sale_container[data-v-3aaf2527] {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_cal[data-v-3aaf2527] {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
}
.input-custom[data-v-3aaf2527] {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
}
.table_custom[data-v-3aaf2527] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title[data-v-3aaf2527] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub[data-v-3aaf2527] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list[data-v-3aaf2527] {
    width: 100%;
    display: flex;
    justify-content: center;
}
.data_table[data-v-3aaf2527] {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
}
.table_custom_summary[data-v-3aaf2527] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title_summary[data-v-3aaf2527] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub_summary[data-v-3aaf2527] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list_summary[data-v-3aaf2527] {
    width: 80%;
    display: flex;
    justify-content: center;
}
.data_table_summary[data-v-3aaf2527] {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
}
.delete_btn[data-v-3aaf2527] {
    color: red !important;
}
.btn_sale[data-v-3aaf2527]:disabled {
    filter: saturate(0);
}
.input-custom[data-v-3aaf2527]:disabled {
    filter: saturate(0);
}
.loading_image[data-v-3aaf2527] {
    height: 8rem;
    margin-bottom: -5rem;
}
.header_top_report[data-v-3aaf2527]{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table-custom[data-v-3aaf2527]{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
    margin: 2rem;
}
.table-custom tr th[data-v-3aaf2527]{
    padding: 0.5rem;
    padding-inline: 2rem;
}
.table-custom tr td[data-v-3aaf2527]{
    padding: 0.5rem;
    text-align: center;
}
.table-custom tr td.sum_text[data-v-3aaf2527]{
    text-align: left;
}
.report_table[data-v-3aaf2527]{
    padding: 0.5rem;
}
.loading_image_main[data-v-3aaf2527]{
    margin-block: -5rem;
}
}


@media print {
.menu_custom_print[data-v-1c9010fa]{
    display: none;
}
.print-icon-contrainer[data-v-1c9010fa]{
    display: none;
}
}
@media screen {
.card_container[data-v-1c9010fa] {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
}
.card_header[data-v-1c9010fa] {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}
.card_content[data-v-1c9010fa] {
    padding: 1rem;
}
.img_thumb[data-v-1c9010fa] {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
}
.form_action[data-v-1c9010fa] {
    padding-right: 5rem;
    padding-left: 5rem;
}
.image_preview_container[data-v-1c9010fa] {
    display: flex;
    justify-content: center;
    align-items: center;
}
#image_preview[data-v-1c9010fa] {
    width: 20rem;
    text-align: center;
}
.btn_sale[data-v-1c9010fa] {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
}
.btn_sale[data-v-1c9010fa]:active {
    background: #0686E8;
    color: #FFFFFF;
}
.btn_sale.active[data-v-1c9010fa] {
    background: #0686E8;
    color: #FFFFFF;
}
.button_list[data-v-1c9010fa] {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.sale_container[data-v-1c9010fa] {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_cal[data-v-1c9010fa] {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
}
.input-custom[data-v-1c9010fa] {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
}
.table_custom[data-v-1c9010fa] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title[data-v-1c9010fa] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub[data-v-1c9010fa] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list[data-v-1c9010fa] {
    width: 100%;
    display: flex;
    justify-content: center;
}
.data_table[data-v-1c9010fa] {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
}
.table_custom_summary[data-v-1c9010fa] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title_summary[data-v-1c9010fa] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub_summary[data-v-1c9010fa] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list_summary[data-v-1c9010fa] {
    width: 80%;
    display: flex;
    justify-content: center;
}
.data_table_summary[data-v-1c9010fa] {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
}
.delete_btn[data-v-1c9010fa] {
    color: red !important;
}
.btn_sale[data-v-1c9010fa]:disabled {
    filter: saturate(0);
}
.input-custom[data-v-1c9010fa]:disabled {
    filter: saturate(0);
}
.loading_image[data-v-1c9010fa] {
    height: 8rem;
    margin-bottom: -5rem;
}
.header_top_report[data-v-1c9010fa]{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table-custom[data-v-1c9010fa]{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
}
.table-custom tr th[data-v-1c9010fa]{
    padding: 0.5rem;
    padding-inline: 2rem;
}
.table-custom tr td[data-v-1c9010fa]{
    padding: 0.5rem;
    text-align: center;
}
.table-custom tr td.sum_text[data-v-1c9010fa]{
    text-align: left;
}
.report_table[data-v-1c9010fa]{
    padding: 0.5rem;
}
.loading_image_main[data-v-1c9010fa]{
    margin-block: -5rem;
}
.print-icon-contrainer[data-v-1c9010fa]{
    width: 100%;
    padding:1rem;
}
.print-icon[data-v-1c9010fa]{
    color: #0686E8 !important;
    font-size: 3rem;
    padding: 1rem;
    padding-bottom: 0;
}
}


@media print {
.menu_custom_print[data-v-31024555]{
    display: none;
}
.print-icon-contrainer[data-v-31024555]{
    display: none;
}
.ist-container-profit-report[data-v-31024555]{
    width: 100%;
}
.list-container-profit-report[data-v-31024555]{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: auto;
}
.profit-text-head[data-v-31024555]{
    background: #80d9fc;
    border: 1px solid #000000;
    font-size: 2rem;
}
.font_size_1[data-v-31024555]{
    font-size: 1.3rem;
}
.list-container-profit-report div[data-v-31024555]{
    text-align: center;
}
.print-table[data-v-31024555]{
    width: 100%;
    font-size: 1rem;
    font-weight: 100;
    border-collapse: collapse;
}
.print-table th[data-v-31024555]{
    font-weight: 300;
    padding: 0.2rem;
}
.print-table td[data-v-31024555]{
    font-weight: 300;
    text-align: center;
}
}
@media screen {
.print-table[data-v-31024555]{
    width: 95%;
    font-size: 1rem;
    border-collapse: collapse;
}
.print-table th[data-v-31024555]{
    font-weight: 300;
    padding: 0.2rem;
}
.print-table td[data-v-31024555]{
    font-weight: 200;
    text-align: center;
}
.page_print[data-v-31024555]{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 2rem;
}
.font_size_1[data-v-31024555]{
    font-size: 1.3rem;
}
.list-container-profit-report[data-v-31024555]{
    padding: 1rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    height: auto;
}
.list-container-profit-report div[data-v-31024555]{
    text-align: center;
}
.profit-text-head[data-v-31024555]{
    background: #80d9fc;
    border: 1px solid #000000;
    font-size: 2rem;
}
.card_container[data-v-31024555] {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
}
.card_header[data-v-31024555] {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}
.card_content[data-v-31024555] {
    padding: 1rem;
}
.img_thumb[data-v-31024555] {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
}
.form_action[data-v-31024555] {
    padding-right: 5rem;
    padding-left: 5rem;
}
.image_preview_container[data-v-31024555] {
    display: flex;
    justify-content: center;
    align-items: center;
}
#image_preview[data-v-31024555] {
    width: 20rem;
    text-align: center;
}
.btn_sale[data-v-31024555] {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
}
.btn_sale[data-v-31024555]:active {
    background: #0686E8;
    color: #FFFFFF;
}
.btn_sale.active[data-v-31024555] {
    background: #0686E8;
    color: #FFFFFF;
}
.button_list[data-v-31024555] {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.sale_container[data-v-31024555] {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_cal[data-v-31024555] {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
}
.input-custom[data-v-31024555] {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
}
.table_custom[data-v-31024555] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title[data-v-31024555] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub[data-v-31024555] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list[data-v-31024555] {
    width: 100%;
    display: flex;
    justify-content: center;
}
.data_table[data-v-31024555] {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
}
.table_custom_summary[data-v-31024555] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title_summary[data-v-31024555] {
    font-size: 1.2rem;
    padding: 0.5rem;
    background: #0686E8;;
    color: #ffffff;
}
.head_title_sub_summary[data-v-31024555] {
    font-size: 1.1rem;
    padding: 0.5rem;
    background: #0686E8;;
    color: #ffffff;
}
.table_list_summary[data-v-31024555] {
    width: 80%;
    display: flex;
    justify-content: center;
}
.data_table_summary[data-v-31024555] {
    padding: 0.5rem;
    font-size: 1rem;
    text-align: center;
}
.delete_btn[data-v-31024555] {
    color: red !important;
}
.btn_sale[data-v-31024555]:disabled {
    filter: saturate(0);
}
.input-custom[data-v-31024555]:disabled {
    filter: saturate(0);
}
.loading_image[data-v-31024555] {
    height: 8rem;
    margin-bottom: -5rem;
}
.header_top_report[data-v-31024555]{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table-custom[data-v-31024555]{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
}
.table-custom tr th[data-v-31024555]{
    padding: 0.5rem;
    padding-inline: 2rem;
}
.table-custom tr td[data-v-31024555]{
    padding: 0.5rem;
    text-align: center;
}
.table-custom tr td.sum_text[data-v-31024555]{
    text-align: left;
}
.report_table[data-v-31024555]{
    padding: 0.5rem;
}
.loading_image_main[data-v-31024555]{
    margin-block: -5rem;
}
.print-icon-contrainer[data-v-31024555]{
    width: 100%;
    padding:1rem;
}
.print-icon[data-v-31024555]{
    color: #0686E8 !important;
    font-size: 3rem;
    padding: 1rem;
    padding-bottom: 0;
}
}



@media print {
.menu_custom_print[data-v-a9de3bde]{
    display: none;
}
.print-icon-contrainer[data-v-a9de3bde]{
    display: none;
}
.ist-container-profit-report[data-v-a9de3bde]{
    width: 100%;
}
.list-container-profit-report[data-v-a9de3bde]{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: auto;
}
.profit-text-head[data-v-a9de3bde]{
    background: #80d9fc;
    border: 1px solid #000000;
    font-size: 2rem;
}
.font_size_1[data-v-a9de3bde]{
    font-size: 1.3rem;
}
.list-container-profit-report div[data-v-a9de3bde]{
    text-align: center;
}
.print-table[data-v-a9de3bde]{
    width: 100%;
    font-size: 1rem;
    font-weight: 100;
    border-collapse: collapse;
}
.print-table th[data-v-a9de3bde]{
    font-weight: 300;
    padding: 0.2rem;
}
.print-table td[data-v-a9de3bde]{
    font-weight: 300;
    text-align: center;
}
}
@media screen {
.print-table[data-v-a9de3bde]{
    width: 95%;
    font-size: 1rem;
    border-collapse: collapse;
}
.print-table th[data-v-a9de3bde]{
    font-weight: 300;
    padding: 0.2rem;
}
.print-table td[data-v-a9de3bde]{
    font-weight: 200;
    text-align: center;
}
.page_print[data-v-a9de3bde]{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 2rem;
}
.font_size_1[data-v-a9de3bde]{
    font-size: 1.3rem;
}
.list-container-profit-report[data-v-a9de3bde]{
    padding: 1rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    height: auto;
}
.list-container-profit-report div[data-v-a9de3bde]{
    text-align: center;
}
.profit-text-head[data-v-a9de3bde]{
    background: #80d9fc;
    border: 1px solid #000000;
    font-size: 2rem;
}
.card_container[data-v-a9de3bde] {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #EBEDF3;
    border-radius: 0.42rem;
}
.card_header[data-v-a9de3bde] {
    padding: 1.5rem 2rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #EBEDF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}
.card_content[data-v-a9de3bde] {
    padding: 1rem;
}
.img_thumb[data-v-a9de3bde] {
    width: 8rem;
    border-radius: 10px;
    margin: 0.2rem;
}
.form_action[data-v-a9de3bde] {
    padding-right: 5rem;
    padding-left: 5rem;
}
.image_preview_container[data-v-a9de3bde] {
    display: flex;
    justify-content: center;
    align-items: center;
}
#image_preview[data-v-a9de3bde] {
    width: 20rem;
    text-align: center;
}
.btn_sale[data-v-a9de3bde] {
    background: #FFFFFF;
    padding: 0.5rem;
    padding-inline: 2rem;
    color: #0686E8;
    font-weight: bold;
}
.btn_sale[data-v-a9de3bde]:active {
    background: #0686E8;
    color: #FFFFFF;
}
.btn_sale.active[data-v-a9de3bde] {
    background: #0686E8;
    color: #FFFFFF;
}
.button_list[data-v-a9de3bde] {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}
.sale_container[data-v-a9de3bde] {
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form_cal[data-v-a9de3bde] {
    display: flex;
    gap: 1rem;
    font-size: 2rem;
    align-items: center;
}
.input-custom[data-v-a9de3bde] {
    border: 1px solid;
    width: 7rem;
    font-size: 1.5rem;
    padding: 0.5rem;
}
.table_custom[data-v-a9de3bde] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title[data-v-a9de3bde] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub[data-v-a9de3bde] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list[data-v-a9de3bde] {
    width: 100%;
    display: flex;
    justify-content: center;
}
.data_table[data-v-a9de3bde] {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
}
.table_custom_summary[data-v-a9de3bde] {
    margin-top: 5rem;
    border-collapse: collapse;
    width: 100%;
}
.head_title_summary[data-v-a9de3bde] {
    font-size: 2rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.head_title_sub_summary[data-v-a9de3bde] {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table_list_summary[data-v-a9de3bde] {
    width: 80%;
    display: flex;
    justify-content: center;
}
.data_table_summary[data-v-a9de3bde] {
    padding: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
}
.delete_btn[data-v-a9de3bde] {
    color: red !important;
}
.btn_sale[data-v-a9de3bde]:disabled {
    filter: saturate(0);
}
.input-custom[data-v-a9de3bde]:disabled {
    filter: saturate(0);
}
.loading_image[data-v-a9de3bde] {
    height: 8rem;
    margin-bottom: -5rem;
}
.header_top_report[data-v-a9de3bde]{
    padding: 1rem;
    background: #0686E8;
    color: #FFFFFF;
}
.table-custom[data-v-a9de3bde]{
    font-size: 1.2rem;
    border-collapse: collapse;
    margin-bottom: 1rem;
}
.table-custom tr th[data-v-a9de3bde]{
    padding: 0.5rem;
    padding-inline: 2rem;
}
.table-custom tr td[data-v-a9de3bde]{
    padding: 0.5rem;
    text-align: center;
}
.table-custom tr td.sum_text[data-v-a9de3bde]{
    text-align: left;
}
.report_table[data-v-a9de3bde]{
    padding: 0.5rem;
}
.loading_image_main[data-v-a9de3bde]{
    margin-block: -5rem;
}
.print-icon-contrainer[data-v-a9de3bde]{
    width: 100%;
    padding:1rem;
}
.print-icon[data-v-a9de3bde]{
    color: #0686E8 !important;
    font-size: 3rem;
    padding: 1rem;
    padding-bottom: 0;
}
}



.date-picker-container[data-v-77362f1b]{
  display: flex;
  gap: 15px;
}
.card_container[data-v-77362f1b]{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header[data-v-77362f1b]{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img_thumb[data-v-77362f1b]{
  width:8rem;
  border-radius:10px;
  margin:0.2rem;
}
.search_ip[data-v-77362f1b]{
  font-size: 1rem;
}
.search_container[data-v-77362f1b]{
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
.search-form[data-v-77362f1b]{
  width: 20%;
}
.list_data[data-v-77362f1b]{
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 3px;
}
.icon-edit[data-v-77362f1b]{
  color: #0686E8 !important;
}
.icon-delete[data-v-77362f1b]{
  color: #f80a1f !important;
}


.title-percent[data-v-21fc4e26]{
  color: #666363;
  font-size: 16px;
}
.card_container[data-v-21fc4e26]{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header[data-v-21fc4e26]{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card[data-v-21fc4e26]{
  padding:2rem;
}
.image_preview_container[data-v-21fc4e26]{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview[data-v-21fc4e26]{
  width: 20rem;
  text-align: center;
}


.title-percent[data-v-647c5a12]{
  color: #666363;
  font-size: 16px;
}
.card_container[data-v-647c5a12]{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header[data-v-647c5a12]{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card[data-v-647c5a12]{
  padding:2rem;
}
.image_preview_container[data-v-647c5a12]{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview[data-v-647c5a12]{
  width: 20rem;
  text-align: center;
}

@media screen {
    body{
        font-family: 'Prompt' !important;
        font-size: 16px;
    }
    .v-application{
        font-family: 'Prompt' !important;
    }
    .v-application .text-h5{
        font-family: 'Prompt' !important;
    }
    .btn-export{
        padding: 0.5rem;
        border: 1px solid;
        width: 10rem;
        border-radius: 5px;
        font-size: 0.9rem;
        margin: 0.5rem;
    }
    .btn-list{
        display: flex;
        align-content: center;
        justify-content: center;
        gap: 0.5rem;
        padding-bottom: 1rem;
    }
    .reportrange-text{
        border: none !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.48) !important;
        position: relative;
        bottom: 4px;
    }
    .daterangepicker .ranges ul{
        display: flex;
        flex-direction: column;
    }
    .daterangepicker td.active, .daterangepicker td.active:hover {
        background-color: #4E57C0;
        border-color: transparent;
        color: #fff;
    }
    .daterangepicker .ranges li.active {
        background-color: #4E57C0;
        color: #fff;
    }

    .btn_login{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
    }
    .theme--light.v-icon {
        color: #ffffff
    }
    .theme--light.v-application{
        background: #0686E8 !important;
    }
    .menu_custon{
        position: relative;
        z-index: 5000;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .menu_custon ul{
        width: 80%;
        display: flex;
        justify-content: space-around;
    }
    .menu_custon ul li{
        list-style: none;
        cursor: pointer;
    }
    .menu_custon ul li a{
        color: #ffffff;
        font-size: 1.6rem;
    }
    ul.drop_down{
        display: none;
        padding-top: 5rem;
        position: absolute;
        top: -2rem;
        width: 20rem;
        font-size:1rem;
    }

    ul.drop_down li a{
        font-size:1.5rem;
        cursor: pointer;
        text-decoration: none;
    }

    .menu_custon ul li a:hover +  ul.drop_down{
        display: block;
    }

    ul.drop_down li a:active + ul.drop_down{
        display: none;
    }
    ul.drop_down:hover {
        display: block;
    }
    .drop_down li{
        padding: 0.2rem;
        background: #0686E8 !important;
    }
    .v-toast__item--warning{
        background: #eea60a;
    }
    .header_print{
        display: none;
    }
    .page_print{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu_container{
        width: 100%;
        background: white;
        max-height: 3rem;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
    }

}


@media all and (min-width: 600px) and (max-width: 798px)  {
    .menu_custon ul{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .menu_custon ul li{
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .menu_custon ul{
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .menu_custon ul li{
        position: relative;
        list-style: none;
        cursor: pointer;
    }
    .menu_custon ul li a{
        color: #ffffff;
        font-size: 1.6rem;
    }
    ul.drop_down{
        display: none;
        padding-top: 5rem;
        position: relative;
        top: -5rem;
        width: 20rem;
        font-size:1rem;
    }

    ul.drop_down li a{
        font-size:1.5rem;
        cursor: pointer;
        text-decoration: none;
    }

    .menu_custon ul li a:hover +  ul.drop_down{
        display: block;
    }

    ul.drop_down li a:active + ul.drop_down{
        display: none;
    }
    ul.drop_down:hover {
        display: block;
    }
    .drop_down li{
        padding: 0.2rem;
        background: #0686E8 !important;
    }
}

.timepicker {
    border: 1px solid rgb(163, 175, 251);
    text-align: center;
    display: inline;
    border-radius: 4px;
    padding:2px;
}

.timepicker .hh, .timepicker .mm {
    width: 30px;
    outline: none;
    border: none;
    text-align: center;
}

.timepicker.valid {
    border: solid 1px springgreen;
}

.timepicker.invalid {
    border: solid 1px red;
}

@media only screen and (min-width: 1904px) {
    body,html{
        font-size: 16px;
    }
}

/* Desktop */
@media only screen and (min-width: 1264px) and (max-width: 1904px) {
    body,html{
        font-size: 16px;
    }
}

/* Tablet */


/* Phone */
@media only screen and (min-width: 600px) and (max-width: 960px) {
    body,html{
        font-size: 19px;
    }
    .container{
        padding: 0;
    }
    .v-dialog{
        width: 100% !important;
        margin: 0 !important;
    }
    .column-reverse {
        flex-direction: column-reverse;
    }
}

/* Small phone */
@media only screen and (max-width: 600px) {
    body,html{
        font-size: 16px;
    }
    .container{
        padding: 0;
    }
    .menu_custon{
        padding-left: 1rem;
        display: none;
    }
    .v-application ul, .v-application ol{
        padding: 0;
    }
    .menu {
        flex-direction: column;
    }
    .submenu {
        width: 100% !important;
        position: static;
        display: none;
    }

    .submenu li {
        border-bottom: none;
        padding-left: 1rem;
    }

    .menu li {
        margin-bottom: 0 !important;
    }
    .v-dialog{
        width: 100% !important;
        margin: 0 !important;
    }
    .v-dialog > .v-card > .v-card__text{
        padding: 5px;
    }
    .column-reverse {
        flex-direction: column-reverse;
    }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
nav{
    position: fixed;
    z-index: 99;
    width: 100%;
    background: #242526;
    top: 0;
    margin-bottom: 0rem;
}
nav .wrapper{
    position: relative;
    max-width: 1300px;
    padding: 0px 30px;
    height: 70px;
    line-height: 70px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wrapper .logo a{
    color: #f2f2f2;
    font-size: 30px;
    font-weight: 600;
    text-decoration: none;
}
.wrapper .nav-links{
    display: inline-flex;
}
.nav-links li{
    list-style: none;
}
.nav-links li a{
    color: #f2f2f2;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
}
.nav-links li a:hover{
    background: #3A3B3C;
}
.nav-links .mobile-item{
    display: none;
}
.nav-links .drop-menu{
    position: absolute;
    background: #242526;
    width: 220px;
    line-height: 45px;
    top: 85px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
    transition: all 0.3s ease;
    top: 70px;
    opacity: 1;
    visibility: visible;
}
.drop-menu li a{
    width: 100%;
    display: block;
    padding: 0 0 0 15px;
    font-weight: 400;
    border-radius: 0px;
}
.mega-box{
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 30px;
    top: 85px;
    opacity: 0;
    visibility: hidden;
}
.mega-box .content{
    background: #242526;
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-box .content .row{
    width: calc(25% - 30px);
    line-height: 45px;
}
.content .row img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.content .row header{
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
}
.content .row .mega-links{
    margin-left: -40px;
    border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li{
    padding: 0 20px;
}
.row .mega-links li a{
    padding: 0px;
    padding: 0 20px;
    color: #d9d9d9;
    font-size: 17px;
    display: block;
}
.row .mega-links li a:hover{
    color: #f2f2f2;
}
.wrapper .btn{
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    display: none;
}
.wrapper .btn.close-btn{
    position: absolute;
    right: 30px;
    top: 10px;
}

@media screen and (max-width: 970px) {
    .menu_container{
        margin-bottom: 5rem;
    }
    .wrapper .btn{
        display: block;
    }
    .wrapper .nav-links{
        position: fixed;
        height: 100vh;
        width: 100%;
        max-width: 350px;
        top: 0;
        left: -100%;
        background: #242526;
        display: block;
        padding: 50px 10px;
        line-height: 50px;
        overflow-y: auto;
        box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
        transition: all 0.3s ease;
    }
    /* custom scroll bar */
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #242526;
    }
    ::-webkit-scrollbar-thumb {
        background: #3A3B3C;
    }
    #menu-btn:checked ~ .nav-links{
        left: 0%;
    }
    #menu-btn:checked ~ .btn.menu-btn{
        display: none;
    }
    #close-btn:checked ~ .btn.menu-btn{
        display: block;
    }
    .nav-links li{
        margin: 15px 10px;
    }
    .nav-links li a{
        padding: 0 20px;
        display: block;
        font-size: 20px;
    }
    .nav-links .drop-menu{
        position: static;
        opacity: 1;
        top: 65px;
        visibility: visible;
        padding-left: 20px;
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        box-shadow: none;
        transition: all 0.3s ease;
    }
    #showDrop:checked ~ .drop-menu,
    #showDrop1:checked ~ .drop-menu,
    #showDrop2:checked ~ .drop-menu,
    #showDrop3:checked ~ .drop-menu,
    #showMega:checked ~ .mega-box{
        max-height: 100%;
    }
    .nav-links .desktop-item{
        display: none;
    }
    .nav-links .mobile-item{
        display: block;
        color: #f2f2f2;
        font-size: 20px;
        font-weight: 500;
        padding-left: 20px;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.3s ease;
    }
    .nav-links .mobile-item:hover{
        background: #3A3B3C;
    }
    .drop-menu li{
        margin: 0;
    }
    .drop-menu li a{
        border-radius: 5px;
        font-size: 18px;
    }
    .mega-box{
        position: static;
        top: 65px;
        opacity: 1;
        visibility: visible;
        padding: 0 20px;
        max-height: 0px;
        overflow: hidden;
        transition: all 0.3s ease;
    }
    .mega-box .content{
        box-shadow: none;
        flex-direction: column;
        padding: 20px 20px 0 20px;
    }
    .mega-box .content .row{
        width: 100%;
        margin-bottom: 15px;
        border-top: 1px solid rgba(255,255,255,0.08);
    }
    .mega-box .content .row:nth-child(1),
    .mega-box .content .row:nth-child(2){
        border-top: 0px;
    }
    .content .row .mega-links{
        border-left: 0px;
        padding-left: 15px;
    }
    .row .mega-links li{
        margin: 0;
    }
    .content .row header{
        font-size: 19px;
    }
}
nav input{
    display: none;
}

.body-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 0 30px;
}
.body-text div{
    font-size: 45px;
    font-weight: 600;
}




@page {
    size: A4;
    margin: 0;
}


@media print {

    .page_print{
        width: 21cm;
        min-height: 29.7cm;
        padding: 2cm;
        padding-inline: 1cm;
        margin: 1cm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        margin-top: 0;
        padding-top: 0.5cm;
    }

    .header_top_report{
        display: none;
    }



    @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    body{
        font-family: 'Prompt' !important;
        font-size: 16px;
        background: white !important;
    }
    .table-custom{
        font-size: 1.2rem;
        border-collapse: collapse;
    }
    .table-custom tr th{
        padding: 0.5rem;
        padding-inline: 2rem;
        background: #8cd9ff;
    }

    .table-custom tr td{
        padding-inline: 0.2rem;
    }
    .report_table{
        padding: 1rem;
    }
    .menu_custom_print{
        display: none !important;
    }
    .card_header{
        display: none !important;
    }
    .theme--light.v-application{
        background: white !important;
    }
}





/*# sourceMappingURL=app.a99441ec.css.map*/