@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



@media screen {
    body{
        font-family: 'Prompt' !important;
        font-size: 16px;
    }
    .v-application{
        font-family: 'Prompt' !important;
    }
    .v-application .text-h5{
        font-family: 'Prompt' !important;
    }
    .btn-export{
        padding: 0.5rem;
        border: 1px solid;
        width: 10rem;
        border-radius: 5px;
        font-size: 0.9rem;
        margin: 0.5rem;
    }
    .btn-list{
        display: flex;
        align-content: center;
        justify-content: center;
        gap: 0.5rem;
        padding-bottom: 1rem;
    }
    .reportrange-text{
        border: none !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.48) !important;
        position: relative;
        bottom: 4px;
    }
    .daterangepicker .ranges ul{
        display: flex;
        flex-direction: column;
    }
    .daterangepicker td.active, .daterangepicker td.active:hover {
        background-color: #4E57C0;
        border-color: transparent;
        color: #fff;
    }
    .daterangepicker .ranges li.active {
        background-color: #4E57C0;
        color: #fff;
    }

    .btn_login{
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
    }
    .theme--light.v-icon {
        color: #ffffff
    }
    .theme--light.v-application{
        background: #0686E8 !important;
    }
    .menu_custon{
        position: relative;
        z-index: 5000;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .menu_custon ul{
        width: 80%;
        display: flex;
        justify-content: space-around;
    }
    .menu_custon ul li{
        list-style: none;
        cursor: pointer;
    }
    .menu_custon ul li a{
        color: #ffffff;
        font-size: 1.6rem;
    }
    ul.drop_down{
        display: none;
        padding-top: 5rem;
        position: absolute;
        top: -2rem;
        width: 20rem;
        font-size:1rem;
    }

    ul.drop_down li a{
        font-size:1.5rem;
        cursor: pointer;
        text-decoration: none;
    }

    .menu_custon ul li a:hover +  ul.drop_down{
        display: block;
    }

    ul.drop_down li a:active + ul.drop_down{
        display: none;
    }
    ul.drop_down:hover {
        display: block;
    }
    .drop_down li{
        padding: 0.2rem;
        background: #0686E8 !important;
    }
    .v-toast__item--warning{
        background: #eea60a;
    }
    .header_print{
        display: none;
    }
    .page_print{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .menu_container{
        width: 100%;
        background: white;
        max-height: 3rem;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
    }

}


@media all and (min-width: 600px) and (max-width: 798px)  {
    .menu_custon ul{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .menu_custon ul li{
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .menu_custon ul{
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .menu_custon ul li{
        position: relative;
        list-style: none;
        cursor: pointer;
    }
    .menu_custon ul li a{
        color: #ffffff;
        font-size: 1.6rem;
    }
    ul.drop_down{
        display: none;
        padding-top: 5rem;
        position: relative;
        top: -5rem;
        width: 20rem;
        font-size:1rem;
    }

    ul.drop_down li a{
        font-size:1.5rem;
        cursor: pointer;
        text-decoration: none;
    }

    .menu_custon ul li a:hover +  ul.drop_down{
        display: block;
    }

    ul.drop_down li a:active + ul.drop_down{
        display: none;
    }
    ul.drop_down:hover {
        display: block;
    }
    .drop_down li{
        padding: 0.2rem;
        background: #0686E8 !important;
    }
}

.timepicker {
    border: 1px solid rgb(163, 175, 251);
    text-align: center;
    display: inline;
    border-radius: 4px;
    padding:2px;
}

.timepicker .hh, .timepicker .mm {
    width: 30px;
    outline: none;
    border: none;
    text-align: center;
}

.timepicker.valid {
    border: solid 1px springgreen;
}

.timepicker.invalid {
    border: solid 1px red;
}
