
@page {
    size: A4;
    margin: 0;
}


@media print {

    .page_print{
        width: 21cm;
        min-height: 29.7cm;
        padding: 2cm;
        padding-inline: 1cm;
        margin: 1cm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        margin-top: 0;
        padding-top: 0.5cm;
    }

    .header_top_report{
        display: none;
    }



    @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    body{
        font-family: 'Prompt' !important;
        font-size: 16px;
        background: white !important;
    }
    .table-custom{
        font-size: 1.2rem;
        border-collapse: collapse;
    }
    .table-custom tr th{
        padding: 0.5rem;
        padding-inline: 2rem;
        background: #8cd9ff;
    }

    .table-custom tr td{
        padding-inline: 0.2rem;
    }
    .report_table{
        padding: 1rem;
    }
    .menu_custom_print{
        display: none !important;
    }
    .card_header{
        display: none !important;
    }
    .theme--light.v-application{
        background: white !important;
    }
}


